body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  padding: 0;
}

textarea {
  font-family: "Poppins", sans-serif;
}

img {
  max-width: 100%;
}

.categoryslider .slick-slide {
  padding: 10px;
}
* {
  box-sizing: border-box;
}
.categoryslider .slick-dots li button:before {
  color: #78c400;
  font-size: 14px;
}
.prevview::before,
.nextview:before {
  display: none;
}
.get-in-toch {
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 1px solid #ccc;
}

.icon-holder {
  margin-bottom: 20px;
  border: 4px solid #fff;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  display: inline-block;
  line-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bullet_icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
}
.bullet_icons li {
  margin: 10px 0;
  position: relative;
  font-size: 16px;
  flex-basis: 50%;
  font-family: "Poppins", sans-serif;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  height: 48px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
}
.react-tel-input .form-control {
  width: 100% !important;
  height: 48px !important;
  border-radius: 0px !important;
}

input::file-selector-button {
  background: #11b4f5;
  border: 0;
  border-radius: 6px;

  transition: all 0.25s;
  color: #fff;
  padding: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  margin-top: 3px;
  padding: 5px 10px;
}
div:where(.swal2-container)
  button:where(.swal2-styled).swal2-default-outline:focus {
  box-shadow: none !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.error {
  color: red;
}

.swal-cancel-button-class {
  border-radius: 10px !important;
}

button.swal2-confirm.swal2-styled,
button.swal2-confirm.swal2-styled:focus {
  background-color: #11b4f5;
  box-shadow: none;
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 9999;
}

.special-label {
  display: none;
}

.StripeElement {
  border: 1px solid #ccc;
  padding: 15px;
}
